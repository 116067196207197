@import '../../../../styles/variables';

.hero {
    &_wrapper {
        background-image: url('../../../../assets/background-light.png');
        background-color: $bg-violet;
        background-size: initial;
        transition: opacity 200ms;
        padding: 20px 15px 60px 15px;
        overflow: hidden;
        box-shadow: inset 0 -15px 15px rgba(0, 0, 0, 0.2);

        @media (min-width: $desktop-sm) {
            padding: 20px 20px 60px 20px;
        }
    }

    &_header {
        width: 287px;
        height: 102px;
        margin: 0 auto 20px auto;
    }

    &_logo {
        margin: 0 auto;
    }

    &_content {
        width: 100%;
        padding: 40px 15px;
        border-radius: 35px;
        background: linear-gradient(180deg, #15012f 0%, #39027d 100%);
        box-shadow: 0 24px 24px -20px rgba(0, 0, 0, 0.8);
        font-size: 26px;
        line-height: 140%;

        @media (min-width: $desktop-sm) {
            font-size: 24px;
            line-height: 140%;
            width: 944px;
            margin: 10px auto 0 auto;
            padding: 50px 35px;
        }
    }

    &_title {
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.7px;
        color: $white;
        font-family: 'Autour One', cursive;

        @media (max-width: $mobile-lg) {
            font-size: 30px;
        }

        @media (max-width: $mobile-sm) {
            font-size: 24px;
        }
    }

    &_snakesRow {
        display: flex;
        justify-content: center;
        gap: 0;
        margin: 22px auto 32px auto;

        @media (min-width: $mobile-lg) {
            gap: 34px;
            margin: 22px auto 42px auto;
        }

        @media (min-width: $desktop-sm) {
            gap: 36px;
            margin: 22px auto 42px auto;
        }
    }

    &_snakesCol {
        div {
            border: 2px solid transparent;
            border-radius: 13px;
            cursor: pointer;

            &:hover {
                border-color: #aa4be1;
            }

            &.selected {
                border-color: #aa4be1;
                transform: scale(1.1);
                transition: all 0.55s ease-out;
            }

            img {
                width: 39px;
                height: 80px;
                margin: 6px;
                border-radius: 8px;

                @media (min-width: $mobile-lg) {
                    width: 47px;
                    height: 96px;
                }

                @media (min-width: $desktop-sm) {
                    width: 47px;
                    height: 96px;
                }

                &.pulse {
                    animation: snakePulse 2s ease-in-out;
                }
            }
        }
    }

    &_row {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 15px;
        margin: 30px auto 0 auto;

        @media (min-width: $desktop-sm) {
            margin: 70px auto 0 auto;
        }
    }

    &_column {
        width: 33%;
        text-align: center;
        color: $text-second;
        font-family: 'Ubuntu', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.72px;

        @media (min-width: $mobile-lg) {
            font-size: 16px;
        }

        @media (min-width: $tablet-sm) {
            width: auto;
            text-align: left;
        }

        @media (min-width: $desktop-sm) {
            font-size: 18px;
            letter-spacing: 1.662px;
        }
    }

    &_icon {
        display: inline-block;
        float: none;
        width: 40px;
        height: 40px;
        margin-bottom: 5px;

        @media (min-width: $tablet-sm) {
            display: block;
            float: left;
            margin-right: 10px;
        }

        @media (min-width: $desktop-sm) {
            width: 47px;
            height: 47px;
        }
    }

    &_span {
        margin: 5px 0;

        @media (max-width: $tablet-sm) {
            margin: 0;
        }
    }
}

@keyframes snakePulse {
    0% {
        opacity: 1;
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(76, 28, 134, 0.2);
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
        transform: scale(1.15);
        box-shadow: 0 0 0 0 rgba(76, 28, 134, 0.4);
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(76, 28, 134, 0);
    }
}

@-webkit-keyframes snakePulse {
    0% {
        opacity: 1;
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(76, 28, 134, 0.2);
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
        transform: scale(1.15);
        box-shadow: 0 0 0 0 rgba(76, 28, 134, 0.4);
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(76, 28, 134, 0);
    }
}
