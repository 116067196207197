// colors
$main-violet: #7508ff;
$bg-violet: #440494;
$bg-violet-dark: #22024a;
$bg-section: rgba(117, 8, 255, 0.07);
$bg-tip: #f5eeff;
$bg-footer-bottom: #250053;
$bg-footer: #2e0367;
$bg-rhomb: rgba(117, 8, 255, 0.5);
$bg-key: #ba83ff;
$white: #ffffff;
$yellow: #ffc213;
$bg-action-button: #fe5711;
$bg-action-button-hover: #e84400;
$text-second: #d7b7ff;
$bg-section-about: #370279;
$bg-section-features: #30026b;
$bg-section-footer: #150034;

// media
$desktop-lg: 1920px;
$desktop-md: 1440px;
$desktop-sm: 1024px;
$tablet: 962px;
$tablet-sm: 768px;
$mobile-lg: 576px;
$mobile-md: 414px;
$mobile-sm: 360px;
