@import '../../../../styles/variables';

.footer {
    background-color: $bg-section-footer;
    overflow: hidden;

    .wrapper {
        position: relative;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin: 80px auto;
        color: #ffffff;

        @media (max-width: $desktop-md) {
            margin-left: 40px;
            margin-right: 40px;
        }
        @media (max-width: $mobile-lg) {
            flex-flow: column;
            margin-left: 24px;
            margin-right: 24px;
        }

        .about-us {
            max-width: 430px;
            text-align: left;

            @media (max-width: $desktop-md) {
                max-width: 285px;
            }
            @media (max-width: $tablet) {
                flex-grow: 1;
                max-width: none;
                margin-bottom: 60px;
            }
            @media (max-width: $mobile-lg) {
                margin-bottom: 48px;
            }

            h5 {
                margin-bottom: 32px;
            }

            .fun {
                color: #ffd323;
            }
        }

        .logo {
            display: flex;
            align-items: center;

            @media (max-width: $desktop-md) {
                margin-top: 10px;
            }
            @media (max-width: $mobile-lg) {
                align-self: center;
                margin-top: 0;
                margin-bottom: 48px;
            }
        }

        .terms-use {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .apply {
                margin-bottom: 18px;
                text-transform: uppercase;

                @media (max-width: $tablet) {
                    margin-bottom: 14px;
                }
                @media (max-width: $mobile-sm) {
                    margin-bottom: 8px;
                }
            }

            .links {
                & > a {
                    color: #ffffff;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .scroll-button {
        position: absolute;
        top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        z-index: 99;
        cursor: pointer;
    }

    .copyright {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #896ab1;
        background-color: $bg-footer-bottom;

        p {
            @media (max-width: $mobile-sm) {
                max-width: 210px;
            }
        }
    }
}
