@import '../../../../styles/variables';

.tutorial-section {
    position: relative;
    background: linear-gradient(360deg, #160130 0%, #37017c 100%), #d9d9d9;
    box-shadow: inset 0 -15px 15px rgba(0, 0, 0, 0.2);
    color: $white;
    padding-bottom: 110px !important;

    @media (min-width: $desktop-sm) {
        padding-bottom: 220px !important;
    }

    .tutorial-snake {
        position: absolute;
        bottom: -32px;
        left: 5%;
        width: 167px;
        height: 113px;

        @media (min-width: $desktop-sm) {
            bottom: -85px;
            width: 436px;
            height: 296px;
        }
    }
}

.tutorial.wrapper {
    .title-wrapper {
        text-align: left;

        .title-text {
            margin-top: 24px;
            margin-bottom: 24px;

            @media (min-width: $mobile-lg) {
                margin-bottom: 40px;
            }
            @media (min-width: $desktop-sm) {
                margin-bottom: 48px;
            }
        }
    }

    .title-action {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;

        @media (min-width: $mobile-lg) {
            margin-top: 70px;
            margin-bottom: 70px;
        }
        @media (min-width: $tablet-sm) {
            margin-top: 70px;
            margin-bottom: 70px;
        }
    }

    .steps-wrapper {
        position: relative;

        .box-shadow {
            position: absolute;
            width: 100%;
            height: calc(100% - 2%);
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            box-shadow: 0 0 3px rgba(186, 131, 255, 0.5);
            z-index: -1;

            @media (min-width: $mobile-md) {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            @media (min-width: $tablet-sm) {
                border-top-left-radius: 13px;
                border-top-right-radius: 13px;
            }
            @media (min-width: $desktop-sm) {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
            @media (min-width: $desktop-lg) {
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
            }
        }
    }
}
