@import '../../../../styles/variables';

.container.small {
    background-color: $bg-section-features;
    color: $white;
    box-shadow: inset 0 -15px 15px rgba(0, 0, 0, 0.2);

    @media (min-width: $desktop-sm) {
        padding-left: 16px;
        padding-right: 16px;
    }
    @media (min-width: $desktop-lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.features.wrapper {
    @media (min-width: $desktop-sm) {
        max-width: 1410px;
    }

    .title {
        @media (min-width: $desktop-sm) {
            padding-left: 24px;
        }
        @media (min-width: $desktop-lg) {
            padding-left: 30px;
        }
    }

    .feature-items {
        display: flex;
        flex-direction: column;

        @media (min-width: $desktop-sm) {
            flex-direction: row;
        }

        .features-wrapper {
            display: flex;
            gap: 20px;

            @media (min-width: $desktop-sm) {
                flex-basis: 50%;
            }

            .feature-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-basis: 50%;
                margin-top: 32px;

                @media (min-width: $mobile-lg) {
                    margin-top: 40px;
                }
                @media (min-width: $tablet-sm) {
                    margin-top: 68px;
                }
                @media (min-width: $desktop-sm) {
                    margin-top: 40px;
                }

                .feature-img {
                    margin-bottom: 20px;

                    @media (min-width: $mobile-md) {
                        margin-bottom: 24px;
                    }
                    @media (min-width: $mobile-lg) {
                        margin-bottom: 32px;
                    }
                    @media (min-width: $tablet-sm) {
                        margin-bottom: 40px;
                    }
                }

                .feature-title {
                    margin-top: calc(
                        18px + (34 - 18) * ((100vw - 320px) / (1900 - 320))
                    );

                    @media (min-width: $tablet-sm) {
                        max-width: 300px;
                    }
                    @media (min-width: $desktop-sm) {
                        max-width: 210px;
                    }
                    @media (min-width: $desktop-lg) {
                        max-width: 282px;
                    }
                }
            }
        }

        .features-wrapper:nth-child(2) {
            @media (min-width: $desktop-sm) {
                margin-left: 20px;
            }
        }
    }
}
