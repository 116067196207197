.swiper {
    padding: 0 24px !important;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    @media (max-width: 340px) {
        left: 12px;
    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fe5711 !important;
}

.swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    opacity: 1 !important;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background: #290556;
    border-radius: 20px;
    height: auto;
    box-shadow: 0px 20px 21px 0px rgba(16, 2, 34, 0.6);

    .card_content {
        text-align: left;
        padding: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));

        .card_title {
            font-size: 26px;
            margin-bottom: 21px;

            @media (max-width: 360px) {
                font-size: 24px;
            }
        }

        .card_text {
            font-size: 18px;

            @media (max-width: 360px) {
                font-size: 16px;
            }
        }
    }
}

.swiper-wrapper {
    height: auto !important;
}

.swiper-action {
    max-width: 200px;
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .my-button-prev img,
    .my-button-next img {
        width: 20px;
        height: 30px;
        position: relative;
        z-index: 100;
        cursor: pointer;
    }
}
