@import 'variables';

/* latin-ext */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/ubuntu-latin-ext-400.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/ubuntu-latin-400.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/ubuntu-latin-ext-500.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/ubuntu-latin-500.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/ubuntu-latin-ext-700.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/ubuntu-latin-700.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Autour One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/autour-one-latin-ext-400.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Autour One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/aq/web/snake-unity/fonts/autour-one-latin-400.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
    font-size: 18px; /*for using REM units*/
}

body {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.25;
    text-align: center;
    color: #000000;

    @media (min-width: $mobile-lg) {
        font-size: 18px;
    }
}

h2,
h4,
h5 {
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 27px;
    font-weight: 700;
    line-height: 1.22;
    text-align: left;

    @media (min-width: $mobile-md) {
        font-size: 30px;
    }
}

h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.22;

    @media (min-width: $mobile-lg) {
        font-size: 24px;
    }
    @media (min-width: $desktop-sm) {
        font-size: 26px;
    }
}

h5 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;

    @media (min-width: $mobile-md) {
        font-size: 24px;
        line-height: 28px;
    }
}

.body1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;

    @media (max-width: $mobile-md) {
        font-size: 1rem;
    }
}

.body2 {
    font-size: 18px;
    line-height: 21px;

    @media (min-width: $mobile-md) {
        font-size: 24px;
        line-height: 28px;
    }
}
