@import '../../../../styles/variables';

.wrapper-button {
    padding: 0 24px;
}

.container.advantages {
    padding: 32px 4px;
    background-image: url('../../../../assets/background-light.png');
    background-color: $bg-violet;
    background-size: initial;
    box-shadow: inset 0 -15px 15px rgba(0, 0, 0, 0.2);
    color: $white;

    @media (max-width: $tablet-sm) {
        padding: 120px 4px;
    }
}

.advantages.wrapper {
    @media (min-width: $desktop-md) {
        max-width: 1390px;
    }

    .title {
        margin-bottom: 32px;
        margin-left: 20px;

        @media (min-width: $mobile-lg) {
            margin-bottom: 40px;
            margin-left: 0;
            text-align: center;
        }
        @media (min-width: $tablet-sm) {
            margin-left: 20px;
            text-align: left;
        }
    }

    .title-action {
        text-align: center;
        margin: 40px 15px;
        font-size: 24px;

        @media (min-width: $desktop-sm) {
            margin: 70px 15px;
            font-size: 27px;
        }
    }
}
