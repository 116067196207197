@import 'styles/reset';
@import 'styles/normalize';
@import 'styles/typography';
@import 'styles/variables';

:root {
    --overflow: 'initial';
}

html,
body {
    width: 100%;
    height: 100%;
    overflow: var(--overflow);
}

body {
    background-color: #ffffff;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $bg-section;
    }
}

img {
    width: 100%;
    height: auto;
}

em {
    font-style: normal;
}

#root {
    width: 100%;
    height: 100%;
}

.container {
    width: 100%;
    padding: 32px 24px;

    @media (min-width: $mobile-lg) {
        padding: 48px 24px;
    }
    @media (min-width: $tablet-sm) {
        padding: 120px 24px;
    }
    @media (min-width: $desktop-sm) {
        padding: 120px 40px;
    }

    &.colored {
        background-color: $bg-section;
    }
}

.wrapper {
    @media (min-width: $desktop-md) {
        max-width: 1350px;
        margin: 0 auto;
    }
}

.blocked-game {
    width: 100%;
    height: 100vh;
    background-image: url('./assets/blocked-bg-mobile.svg');
    background-position: initial;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: $mobile-lg) {
        background-position: center;
    }
    @media (min-width: $tablet-sm) {
        background-image: url('./assets/blocked-bg-desktop.svg');
    }
}

.tip {
    color: $main-violet;
}

.highlighted {
    color: $bg-action-button;
}

.nowrap {
    white-space: nowrap;
}

.button {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 14px 0;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    color: #ffffff;
    background-color: $main-violet;
    border: none;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: $bg-footer-bottom;
    }
}

.play-button {
    width: 390px;
    cursor: pointer;
    height: 66px;
    border-radius: 15px;
    border: none;
    color: $white;
    font-family: 'Autour One', cursive;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.985px;
    background-color: $bg-action-button;
    background-size: 100% 250%;
    background-image: linear-gradient(
        to top,
        $bg-action-button-hover 0%,
        $bg-action-button-hover 49%,
        $bg-action-button 50%,
        $bg-action-button 100%
    );
    transition: all 400ms ease-in-out;

    @media (max-width: $mobile-lg) {
        width: 310px;
    }

    @media (max-width: $mobile-md) {
        width: 100%;
    }

    &:hover:enabled {
        background-color: $bg-action-button-hover;
        box-shadow: 0 17px 17px -10px rgba(254, 87, 17, 0.5);
        background-position: 0 100%;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.6;
    }

    .button-emoji {
        display: inline-block;
        width: 20px;
        height: 23px;

        @media (min-width: $mobile-lg) {
            width: 20px;
            height: 23px;
        }
    }
}
