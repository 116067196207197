@import '../../../../styles/variables';

.section-about {
    position: relative;
    background-color: $bg-section-about;
    color: $white;
    box-shadow: inset 0 -15px 15px rgba(0, 0, 0, 0.2);
    padding-bottom: 100px !important;

    .about-snake {
        position: absolute;
        bottom: 0px;
        right: 5%;
        width: 200px;
        height: 81px;

        img {
            position: relative;
            top: -8px;
        }
    }
}

.animation-wrapper {
    width: 220px;
    height: 476px;
    overflow: hidden;
    transform: translateZ(0);
    box-shadow:
        0 0 0 8px black,
        0 0 0 13px silver;
    align-self: center;
    margin-bottom: 24px;
    border-radius: 40px;

    @media (min-width: $mobile-md) {
        margin-bottom: 32px;
        width: 305px;
        height: 662px;
    }
    @media (min-width: $mobile-lg) {
        margin-bottom: 48px;
    }
    @media (min-width: $tablet-sm) {
        margin-bottom: 0;
    }
    @media (min-width: $desktop-md) {
        margin-right: 250px;
    }
    @media (min-width: $desktop-sm) {
        width: 324px;
        height: 702px;
    }

    .animation {
        max-width: none;
        width: 100%;
        height: 100%;
    }
}

.about.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (min-width: $tablet-sm) {
        flex-direction: row;
        justify-content: space-around;
    }
    @media (min-width: $desktop-md) {
        justify-content: space-evenly;
    }

    .phone {
        align-self: center;
        margin-bottom: 24px;

        @media (min-width: $mobile-md) {
            margin-bottom: 32px;
        }
        @media (min-width: $mobile-lg) {
            margin-bottom: 48px;
        }
        @media (min-width: $tablet-sm) {
            margin-bottom: 0;
        }
        @media (min-width: $desktop-md) {
            margin-right: 250px;
        }

        img {
            max-width: none;
            width: 235px;
            height: 476px;

            @media (min-width: $mobile-md) {
                width: 329px;
                height: 662px;
            }
            @media (min-width: $desktop-sm) {
                width: 348px;
                height: 702px;
            }
        }
    }

    .about-info {
        text-align: left;

        @media (min-width: $tablet-sm) {
            align-self: center;
            max-width: 330px;
        }
        @media (min-width: $tablet) {
            max-width: 460px;
        }
        @media (min-width: $desktop-md) {
            max-width: 590px;
        }

        .description {
            padding: 40px 0;
        }
    }
}

.about-button {
    @media (max-width: $desktop-sm) {
        button {
            width: 100%;
        }
    }
}
